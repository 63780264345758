<template>
  <v-app class="background">
    <v-navigation-drawer app v-model="drawer" temporary class="pt-16">
      <v-list nav dense>
        <template v-for="item in menu">
          <v-list-item
            v-if="!item.children || $vuetify.breakpoint.mdAndUp"
            :to="item.to"
            :key="item.to"
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
          <v-list-group v-else :key="item.to" :value="true" no-action>
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="child in item.children"
              :exact="child.exact"
              :key="child.to"
              :to="child.to"
            >
              <v-list-item-content>
                <v-list-item-title v-text="child.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </template>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <v-app-bar style="z-index: 40;" color="black">
        <v-app-bar-nav-icon
          v-if="false && $vuetify.breakpoint.mdAndDown"
          @click="drawer = !drawer"
        ></v-app-bar-nav-icon>

        <div class="d-flex align-center">
          <router-link to="/">
            <v-img
              v-if="$vuetify.breakpoint.lgAndUp"
              contain
              height="55"
              width="320"
              :src="require('@/assets/Logo_Main.png')"
            />
            <v-img
              v-if="$vuetify.breakpoint.mdAndDown"
              contain
              height="45"
              width="260"
              :src="require('@/assets/Logo_Main.png')"
            />
          </router-link>
        </div>
        <v-spacer />
        <v-tabs centered v-if="$vuetify.breakpoint.lgAndUp">
          <v-tab v-for="item in menu" :to="item.to" :key="item.to">
            {{ item.title }}
          </v-tab>
        </v-tabs>
        <ProfileAppBar />
      </v-app-bar>
      <router-view />
    </v-main>
    <v-dialog :value="streamPopup" width="500">
      <v-card>
        <v-card-text class="pt-5 text-h5">
          Yayınımızın soru cevap kısmı başlamak üzeredir.
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            depressed
            to="/stage/main"
            class="ma-2"
            @click="streamPopup = false"
          >
            Ana Sahne
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <CookieBar />
  </v-app>
</template>

<script>
import CookieBar from '@/components/CookieBar';
import ProfileAppBar from '@/components/ProfileAppBar';

export default {
  name: 'DefaultLayout',
  components: { ProfileAppBar, CookieBar },
  data() {
    return {
      drawer: false,
      streamPopup: false,
      menu: [
        // { to: '/stage/main', title: 'MAIN HALL' },
        // { to: '/manifesto', title: 'MANIFESTO' },
        // { to: '/expo', title: 'GLOBAL R&D EXPO' },
        // { to: '/awards', title: 'R&D AWARDS' },
        // { to: '/design', title: 'ARÇELİK DESIGN AWARDS' },
        // { to: '/photobooth', title: 'PHOTOBOOTH' }
      ]
    };
  },
  sockets: {
    stream_popup() {
      if (this.$route.name !== 'stage-stage') {
        this.streamPopup = true;
      }
    }
  },
  created() {},
  beforeDestroy() {},
  watch: {},
  methods: {}
};
</script>

<style scoped lang="scss">
@import '~vuetify/src/styles/styles.sass';

.background {
  background-color: #fff !important;
  background-image: url('../assets/Main_Bg.jpg') !important;
  background-position: bottom center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-attachment: fixed !important;
}
</style>
